// /* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React, { useMemo, useEffect, useState } from "react";
// import objectPath from "object-path";
// import ApexCharts from "apexcharts";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import { useHtmlClassService } from "../../../../_metronic/layout";
// import * as donationCrud from "../DownloadReportCrud"
// import { currencyFormatter } from "../ColumnFormatter/ColumnFormatter";
// import moment from "moment";

// export function CustomStatsWidget1({ className, symbolShape, baseColor }) {
//   const uiService = useHtmlClassService();

//   const [findDonation, setFindDonation] = useState();
//   const [totalSum, setTotalSum] = useState()
//   const fetchDonation = async () => {
//     const findDonation = await donationCrud.findDonation();
//     setFindDonation(findDonation);
//   };
//   // useEffect(() => {
//   //   fetchDonation();
//   // }, []);

//   const layoutProps = useMemo(() => {
//     return {
//       colorsGrayGray500: objectPath.get(
//         uiService.config,
//         "js.colors.gray.gray500"
//       ),
//       colorsGrayGray200: objectPath.get(
//         uiService.config,
//         "js.colors.gray.gray200"
//       ),
//       colorsGrayGray300: objectPath.get(
//         uiService.config,
//         "js.colors.gray.gray300"
//       ),
//       colorsThemeBaseSuccess: objectPath.get(
//         uiService.config,
//         `js.colors.theme.base.${baseColor}`
//       ),
//       colorsThemeLightSuccess: objectPath.get(
//         uiService.config,
//         `js.colors.theme.light.${baseColor}`
//       ),
//       fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
//     };
//   }, [uiService, baseColor]);


//   let monthlySumArr = []

//   const chart = () => {

//     findDonation && findDonation.data.donation_monthly.map(item => {
//       monthlySumArr.push(item.monthly_sum)
//     })

    

//     let trxMonthArr = []
//     findDonation && findDonation.data.donation_monthly.map(item => {
//       trxMonthArr.push(item.trx_month)
//     })

//     const element = document.getElementById("kt_stats_widget_11_chart");

//     const options = {
//     series: [
//       {
//         name: "Donasi",
//         // data: [40000, 250000, 3000000, 3200000, 555000, 123000, 1000000, 5200000, 200000, 780000, 900000],
//         data: monthlySumArr,
//       },
//     ],
//     chart: {
//       type: "area",
//       height: 150,
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         enabled: false,
//       },
//       sparkline: {
//         enabled: true,
//       },
//     },
//     plotOptions: {},
//     legend: {
//       show: false,
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     fill: {
//       type: "solid",
//       opacity: 1,
//     },
//     stroke: {
//       curve: "smooth",
//       show: true,
//       width: 3,
//       colors: [layoutProps.colorsThemeBaseSuccess],
//     },
//     xaxis: {
//       // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep", "Oct", "Nov", "Des"],
//       categories: trxMonthArr,
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//       labels: {
//         show: false,
//         style: {
//           colors: layoutProps.colorsGrayGray500,
//           fontSize: "12px",
//           fontFamily: layoutProps.fontFamily,
//         },
//       },
//       crosshairs: {
//         show: false,
//         position: "front",
//         stroke: {
//           color: layoutProps.colorsGrayGray300,
//           width: 1,
//           dashArray: 3,
//         },
//       },
//       tooltip: {
//         enabled: true,
//         formatter: undefined,
//         offsetY: 0,
//         style: {
//           fontSize: "12px",
//           fontFamily: layoutProps.fontFamily,
//         },
//       },
//     },
//     yaxis: {
//       min: 0,
//       labels: {
//         show: false,
//         style: {
//           colors: layoutProps.colorsGrayGray500,
//           fontSize: "12px",
//           fontFamily: layoutProps.fontFamily,
//         },
//       },
//     },
//     states: {
//       normal: {
//         filter: {
//           type: "none",
//           value: 0,
//         },
//       },
//       hover: {
//         filter: {
//           type: "none",
//           value: 0,
//         },
//       },
//       active: {
//         allowMultipleDataPointsSelection: false,
//         filter: {
//           type: "none",
//           value: 0,
//         },
//       },
//     },
//     tooltip: {
//       style: {
//         fontSize: "12px",
//         fontFamily: layoutProps.fontFamily,
//       },
//       y: {
//         formatter: function(val) {
//           return currencyFormatter(val)
//         },
//       },
//     },
//     colors: [layoutProps.colorsThemeLightSuccess],
//     markers: {
//       colors: [layoutProps.colorsThemeLightSuccess],
//       strokeColor: [layoutProps.colorsThemeBaseSuccess],
//       strokeWidth: 3,
//     },
//   };

//     if (!element) {
//       return
//     }

//     // const options = getChartOption(layoutProps, findDonation);
//     const chart = new ApexCharts(element, options);
//     chart.render();
//     return function cleanUp() {
//       chart.destroy();
//     };
//   }
//   chart();

//   const data = monthlySumArr.reduce(function(prevValue, currentValue) {
//       return prevValue + currentValue;
//     }, 0)
//     console.log(data);


//   useEffect(() => {
//     fetchDonation();
    
//   }, [layoutProps]);


//   return (
//     <div className={`card card-custom ${className}`}>
//       <div className="card-body p-0">
//         <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
//           <span
//             className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}
//           >
//             <span className="symbol-label">
//               <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
//                 <SVG
//                   src={toAbsoluteUrl(
//                     "/media/svg/icons/Layout/Layout-4-blocks.svg"
//                   )}
//                 ></SVG>
//               </span>
//             </span>
//           </span>
//           <div className="d-flex flex-column text-right">
//             <span className="text-dark-75 font-weight-bolder font-size-h3">
//               {currencyFormatter(data)}
//             </span>
//             <span className="text-muted font-weight-bold mt-2">
//               Total Donasi anda dalam 12 bulan terakhir
//             </span>
//           </div>
//         </div>
//         <div
//           id="kt_stats_widget_11_chart"
//           className="card-rounded-bottom"
//           style={{ height: "150px" }}
//         ></div>
//       </div>
//     </div>
//   );
// }

// // function getChartOption(layoutProps, findDonation) {
// //   const options = {
// //     series: [
// //       {
// //         name: "Donasi",
// //         data: [40000, 250000, 3000000, 3200000, 555000, 123000, 1000000, 5200000, 200000, 780000, 900000],
// //       },
// //     ],
// //     chart: {
// //       type: "area",
// //       height: 150,
// //       toolbar: {
// //         show: false,
// //       },
// //       zoom: {
// //         enabled: false,
// //       },
// //       sparkline: {
// //         enabled: true,
// //       },
// //     },
// //     plotOptions: {},
// //     legend: {
// //       show: false,
// //     },
// //     dataLabels: {
// //       enabled: false,
// //     },
// //     fill: {
// //       type: "solid",
// //       opacity: 1,
// //     },
// //     stroke: {
// //       curve: "smooth",
// //       show: true,
// //       width: 3,
// //       colors: [layoutProps.colorsThemeBaseSuccess],
// //     },
// //     xaxis: {
// //       categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep", "Oct", "Nov", "Des"],
// //       axisBorder: {
// //         show: false,
// //       },
// //       axisTicks: {
// //         show: false,
// //       },
// //       labels: {
// //         show: false,
// //         style: {
// //           colors: layoutProps.colorsGrayGray500,
// //           fontSize: "12px",
// //           fontFamily: layoutProps.fontFamily,
// //         },
// //       },
// //       crosshairs: {
// //         show: false,
// //         position: "front",
// //         stroke: {
// //           color: layoutProps.colorsGrayGray300,
// //           width: 1,
// //           dashArray: 3,
// //         },
// //       },
// //       tooltip: {
// //         enabled: true,
// //         formatter: undefined,
// //         offsetY: 0,
// //         style: {
// //           fontSize: "12px",
// //           fontFamily: layoutProps.fontFamily,
// //         },
// //       },
// //     },
// //     yaxis: {
// //       min: 0,
// //       labels: {
// //         show: false,
// //         style: {
// //           colors: layoutProps.colorsGrayGray500,
// //           fontSize: "12px",
// //           fontFamily: layoutProps.fontFamily,
// //         },
// //       },
// //     },
// //     states: {
// //       normal: {
// //         filter: {
// //           type: "none",
// //           value: 0,
// //         },
// //       },
// //       hover: {
// //         filter: {
// //           type: "none",
// //           value: 0,
// //         },
// //       },
// //       active: {
// //         allowMultipleDataPointsSelection: false,
// //         filter: {
// //           type: "none",
// //           value: 0,
// //         },
// //       },
// //     },
// //     tooltip: {
// //       style: {
// //         fontSize: "12px",
// //         fontFamily: layoutProps.fontFamily,
// //       },
// //       y: {
// //         formatter: function(val) {
// //           return "Rp." + val + ",00";
// //         },
// //       },
// //     },
// //     colors: [layoutProps.colorsThemeLightSuccess],
// //     markers: {
// //       colors: [layoutProps.colorsThemeLightSuccess],
// //       strokeColor: [layoutProps.colorsThemeBaseSuccess],
// //       strokeWidth: 3,
// //     },
// //   };
// //   return options;
// // }
