/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../../_metronic/layout";
import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import * as donationActions from "../_redux/DownloadReportActions";
import { currencyFormatter } from "../ColumnFormatter/ColumnFormatter";
import { ProgressBar, Button, Spinner } from "react-bootstrap";
import moment from "moment";

export function CustomWidget1({ className }) {
  const uiService = useHtmlClassService();

  const {loadingProgress} = useSelector((state) => state.exportList, shallowEqual)

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

   const dispatch = useDispatch()

  const downloadReport = (value) => {
    const download = dispatch(donationActions.downloadReport(value))
    return download
  }

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-gray py-5">
        <h3 className="card-title font-weight-bolder text-black">Donatur Stat</h3>
      </div>

      <div className="card-body position-relative overflow-hidden">
        <div
          style={{ height: "50px" }}
        ></div>

        <div className="card-spacer mt-n25">
        <div className="row">
          <div className="col-lg-1">

          </div>
          <div className="col-lg-10">
        <div className="row m-0">
          <div className="col-2">
            
          </div>
          <div className="col-lg-8">
            <div className="col bg-light-info px-6 py-8 rounded-xl mt-2 mb-2">
              <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-info font-weight-bold font-size-h6 mt-2"
              >
                Laporan {moment().format("YYYY")}
              </a>
              {loadingProgress ? 
              <Button
                className="btn btn-info float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-info float-right"
                onClick={() => downloadReport(moment().format("YYYY"))}
              >
                Download
              </Button>
            }
            </div>
          </div>
          <div className="col-lg-2">
            
          </div>
        </div>
          <div className="row m-0">
            <div className="col bg-light-danger px-6 py-8 rounded-xl mt-2 mb-2 mr-7">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
               <a
                href="#"
                className="text-danger font-weight-bold font-size-h6 mt-2"
              >
                Laporan {moment().subtract(1, "years").format("YYYY")}
              </a>
              {loadingProgress ? 
              <Button
                className="btn btn-danger float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-danger float-right"
                onClick={() => downloadReport(moment().subtract(1, "years").format("YYYY"))}
              >
                Download
              </Button>
            }
            </div>
            <div className="col bg-light-primary px-6 py-8 mt-2 mb-2 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h6 mt-2"
              >
                Laporan {moment().subtract(2, "years").format("YYYY")}
              </a>
              {loadingProgress ? 
              <Button
                className="btn btn-primary float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-primary float-right"
                onClick={() => downloadReport(moment().subtract(2, "years").format("YYYY"))}
              >
                Download
              </Button>
            }
            </div>
          </div>
          <div className="row m-0">
          <div className="col bg-light-warning px-6 py-8 rounded-xl mb-2 mt-2 mr-7">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
               <a
                href="#"
                className="text-warning font-weight-bold font-size-h6 mt-2"
              >
                Laporan {moment().subtract(3, "years").format("YYYY")}
              </a>
              {loadingProgress ? 
              <Button
                className="btn btn-warning float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-warning float-right"
                onClick={() => downloadReport(moment().subtract(3, "years").format("YYYY"))}
              >
                Download
              </Button>
            }
            </div>
            <div className="col bg-light-success px-6 py-8 mt-2 mb-2 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
               <a
                href="#"
                className="text-success font-weight-bold font-size-h6 mt-2"
              >
                Laporan {moment().subtract(4, "years").format("YYYY")}
              </a>
              {loadingProgress ? 
              <Button
                className="btn btn-success float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-success float-right"
                onClick={() => downloadReport(moment().subtract(4, "years").format("YYYY"))}
              >
                Download
              </Button>
            }
            </div>
          </div>
          </div>
          <div className="col-lg-1">

          </div>
        </div>
        
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}
